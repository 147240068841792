import React, { useEffect } from "react"
import MessengerChat from "../components/messenger"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Header from "../components/common/header"
import { Div, Text, Image, Button, Anchor } from "atomize"
import { PrimaryColor } from "../components/colors"
import Page1 from "../images/uCall x Spa_page-0001.jpg"
import Page2 from "../images/uCall x Spa_page-0002.jpg"
import firebase from "gatsby-plugin-firebase"
import { Helmet } from "react-helmet"

const Spa = () => {
  useEffect(() => {
    firebase.analytics().logEvent("visit_spa")
  }, [])
  return (
    <Layout>
      <MessengerChat />

      <Helmet>
        <meta
          property="og:title"
          content="uCall - Giải pháp callbot gọi tự động cho ngành spa / làm đẹp"
        />
        <meta
          property="og:description"
          content="Giải pháp giúp doanh nghiệp gọi hàng nghìn cuộc gọi tự động, thông minh với 1 cú click chuột"
        />
      </Helmet>
      <SEO
        title="uCall - Giải pháp callbot gọi tự động cho ngành Spa / Làm Đẹp"
        description="uCall - Giải pháp giúp doanh nghiệp gọi hàng nghìn cuộc gọi tự động, thông minh với 1 cú click chuột"
      />
      <Header layout={"layout-1"} />
      <Div
        m="auto"
        p={{
          t: { xs: "6rem", md: "10rem" },
          l: { xs: "1.5rem", md: "2rem" },
          r: { xs: "1.5rem", md: "2rem" },
        }}
        flexDir="row"
        maxW={"800px"}
      >
        <Text
          textSize="display1"
          textWeight="700"
          fontFamily="secondary"
          color="info700"
          m={{ b: "1rem" }}
        >
          Giải pháp callbot gọi tự động cho ngành Spa / Làm Đẹp
        </Text>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/6D0cdl5w2YM"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
        <a
          href={Page1}
          target="_blank"
          onClick={() => {
            firebase.analytics().logEvent(`tap_on_page_1_spa`)
          }}
        >
          <Image src={Page1} w="100%" />
        </a>

        <a
          href={Page2}
          target="_blank"
          onClick={() => {
            firebase.analytics().logEvent(`tap_on_page_2_spa`)
          }}
        >
          <Image src={Page2} w="100%" />
        </a>

        <div className="blink">
          <Anchor
            href="https://growth.ucall.vn/"
            onClick={() => {
              firebase.analytics().logEvent("tap_on_trai_nghiem_ngay_giao_duc")
            }}
            target="_blank"
          >
            <Button
              bg="brand900"
              hoverBg="brand900"
              textColor="medium"
              w="100%"
              rounded="lg"
              m={{ t: "16px", b: "16px" }}
              style={{ letterSpacing: "-0.5px" }}
              onClick={() => {
                firebase.analytics().logEvent("tap_on_trai_nghiem_ngay_spa")
              }}
            >
              Trải Nghiệm Ngay
            </Button>
          </Anchor>
        </div>
        <Text>
          Ngành spa, thẩm mỹ đang có nhiều sự phát triển những năm trở lại đây.
          Không khó để khách hàng có thể tìm kiếm được một địa chỉ làm đẹp. Vậy
          các spa càng cần quan tâm đến việc giữ chân khách hàng, tạo nên những
          khách hàng trung thành. Chính những khách hàng trung thành sẽ là cầu
          nối khiến spa có nhiều khách hàng mới hơn nữa.
        </Text>
        <Text>
          Các spa, cơ sở chăm sóc sức khỏe và làm đẹp thường chưa tối ưu được dữ
          liệu từ khách hàng cũ, hoặc vẫn sử dụng cách gọi điện chăm sóc thủ
          công khá tốn kém.
        </Text>
        <Text
          textSize="display1"
          textWeight="700"
          fontFamily="secondary"
          color={PrimaryColor}
          m={{ b: "1rem", t: "2rem" }}
        >
          Giải Pháp
        </Text>
        <Text>
          Sử dụng callbot của uCall mang đến một quy trình chăm sóc khách hàng
          tự động và thông minh hơn. Thông qua ứng dụng callbot của uCall, các
          spa, phòng khám có thể
        </Text>
        <ul>
          <li>
            Gọi điện đặt lịch, nhắc lịch cho khách hàng tại các buổi trị liệu
          </li>
          <li>
            Cá nhân hóa việc chăm sóc khách hàng, hỏi xin feedback sau khi sử
            dụng dịch vụ
          </li>
          <li>
            Gửi lời chúc mừng, giới thiệu khuyến mãi tới khách hàng cũ Upsales
            nhờ vào việc nhắc lịch thăm khám định kỳ
          </li>
          <li>
            Tối ưu chi phí đến 70% so với telesales Dễ dàng thống kê, xem lại
            cuộc gọi
          </li>
          <li>
            Hệ thống của uCall tích hợp các CRM mang lại quá trình chăm sóc
            khách hàng hiệu quả và đồng bộ nhất
          </li>
        </ul>
        <Text
          textSize="display1"
          textWeight="700"
          fontFamily="secondary"
          color={PrimaryColor}
          m={{ b: "1rem", t: "2rem" }}
        >
          Kết Quả Đạt Được
        </Text>
        <ul>
          <li>
            Sử dụng callbot của uCall giúp doanh nghiệp tối ưu 50% chi phí và
            thời gian so với tổng đài truyền thống (cắt giảm chi phí nhân sự,
            chi phí quản lý, vận hành)
          </li>
          <li>Tăng năng suất gấp đôi so với tổng đài viên thuê ngoài</li>
          <li>
            Nhanh chóng hoàn thành nhiệm vụ, gọi điện 5000 cuộc chỉ trong 1h
          </li>
          <li>
            Đặc biệt, so với người gọi khó kiểm soát chất lượng do nhân viên
            telesales có thể có cảm giác buồn bực, chán nản sau khi bị khách từ
            chối, hoặc cảm xúc ảnh hưởng đến chất lượng cuộc gọi thì tổng đài tự
            động của uCall giúp doanh nghiệp có thể dễ dàng kiểm soát chất lượng
            cuộc gọi.
          </li>
        </ul>
        <Div h="120px" />
      </Div>
      <a href="tel:+84327335877" className="call-to-us">
        <div className="call-to-us__label">Liên Hệ</div>
      </a>
    </Layout>
  )
}

export default Spa
